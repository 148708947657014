$publicPath:'';

@import "./../assets/css/global-variable.scss";

.g-grid-box {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #1b1c4c;
    background-size: 1400px;
}

.grid-box {
    width: 100%;
    position: relative;
    margin-top: -50px;

}
.item-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .item-box-icon {
        width: 168px;
        height: 194px;

        @for $i from 1 through 8 {
            &.icon#{$i} {
                background: url("~assets/img/home/icon#{$i}.png") no-repeat;
                background-size: 100%;
            }
        }

        &.icon-bill-icon1 {
            background: url("~assets/img/home/icon1-bill-icon1.png") no-repeat;
            background-size: 100%;
        }
        &.icon-bill-icon2 {
            background: url("~assets/img/home/icon1-bill-icon2.png") no-repeat;
            background-size: 100%;
        }
        &.icon-base-icon1 {
            background: url("~assets/img/home/icon2-base-icon1.png") no-repeat;
            background-size: 100%;
        }
        &.icon-base-icon2 {
            background: url("~assets/img/home/icon2-base-icon2.png") no-repeat;
            background-size: 100%;
        }
        &.icon-base-icon3 {
            background: url("~assets/img/home/icon2-base-icon3.png") no-repeat;
            background-size: 100%;
        }
        &.icon-base-icon4 {
            background: url("~assets/img/home/icon2-base-icon4.png") no-repeat;
            background-size: 100%;
        }
        &.icon-base-icon5 {
            background: url("~assets/img/home/icon2-base-icon5.png") no-repeat;
            background-size: 100%;
        }
        &.icon-product-icon1 {
            background: url("~assets/img/home/icon3-product-icon1.png") no-repeat;
            background-size: 100%;
        }

        &.icon-product-icon2 {
            background: url("~assets/img/home/icon3-product-icon2.png") no-repeat;
            background-size: 100%;
        }
        &.icon-zhihu-icon1 {
            background: url("~assets/img/home/icon6-zhihu-icon1.png") no-repeat;
            background-size: 100%;
        }
        &.icon-zhihu-icon2 {
            background: url("~assets/img/home/icon6-zhihu-icon2.png") no-repeat;
            background-size: 100%;
        }
        &.icon-zhihu-icon3 {
            background: url("~assets/img/home/icon6-zhihu-icon3.png") no-repeat;
            background-size: 100%;
        }
        &.icon1-tanshang-icon1 {
            background: url("~assets/img/home/icon1-tanshang-icon1.png") no-repeat;
            background-size: 100%;
        }
        &.icon1-tanzhijian-icon1 {
            background: url("~assets/img/home/icon1-tanzhijian-icon1.png") no-repeat;
            background-size: 100%;
        }
        // &.icon1-tanbaobiao-icon1 {
        //     background: url("~assets/img/home/icon1-tanzhijian-icon1.png") no-repeat;
        //     background-size: 100%;
        // }

    }
    .item-box-title {
        text-align: center;
        margin-top: 20px;
        font-size: 18px;
        color: $text-color-white;
        font-weight: bold;
    }
}
.grid-box-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.grid-box-item {
    width: 233px;
    height: 233px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    cursor: pointer;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms;
    &:hover {
        background: #00995b;
        color: #ffffff;
        transform: scale(1.05);
    }
}
